import React, { memo, useCallback } from 'react';
import { Button, DialogActions } from '@mui/material';
import { CancellationButtonProps, ConfirmationButtonProps } from './BusUnavailabilityPeriodDialogActions.style';
import { FormattedMessage } from 'react-intl';
import { PeriodType } from '../../BusUnavailabilityPeriodDialog';
import {
    DispatcherBusDto,
    useMutationDeleteRemoveBusUnavailability,
    useMutationPostDeclareBusUnavailability,
} from '../../../../../../../backend/gen';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../../../../../utils/constants';

export const BusUnavailabilityPeriodDialogActions = memo(function BusUnavailabilityPeriodDialogActions({
    closeDialog,
    period,
    setPeriod,
    bus,
}: {
    closeDialog: () => void;
    period: PeriodType;
    setPeriod: React.Dispatch<React.SetStateAction<PeriodType>>;
    bus: DispatcherBusDto;
}) {
    const { mutateAsync: declareBusUnavailability } = useMutationPostDeclareBusUnavailability();
    const { mutateAsync: removeBusUnavailability } = useMutationDeleteRemoveBusUnavailability();
    const onClickModifyBusUnavailability = useCallback(() => {
        if (period.start && period.end) {
            declareBusUnavailability({
                unavailabilityUpdateDto: {
                    id: bus?.unavailability?.id || null,
                    bus: bus.name,
                    start: period.start,
                    end: period.end,
                },
            })
                .then(() => closeDialog())
                .catch(() =>
                    toast(
                        <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.requestFailed'} />,
                        errorToastConfig,
                    ),
                );
        } else if (!period.start && !period.end && bus?.unavailability) {
            removeBusUnavailability({ id: bus.unavailability?.id })
                .then(() => closeDialog())
                .catch(() =>
                    toast(
                        <FormattedMessage id={'dispatcher.busUnavailabilityPeriod.requestFailed'} />,
                        errorToastConfig,
                    ),
                );
        } else {
            closeDialog();
        }
    }, [
        bus.name,
        bus.unavailability,
        closeDialog,
        declareBusUnavailability,
        period.end,
        period.start,
        removeBusUnavailability,
    ]);

    const onClickClearPeriod = useCallback(() => {
        setPeriod({ start: null, end: null });
    }, [setPeriod]);

    const onClickCloseDialog = useCallback(() => {
        closeDialog();
    }, [closeDialog]);

    return (
        <DialogActions>
            <Button {...CancellationButtonProps} onClick={onClickCloseDialog}>
                <FormattedMessage id="confirm.default.cancel" />
            </Button>
            <Button {...CancellationButtonProps} onClick={onClickClearPeriod}>
                <FormattedMessage id="dispatcher.busUnavailabilityPeriod.clearPeriod" />
            </Button>
            <Button
                {...ConfirmationButtonProps}
                // Disabled if one of two dates missing or if period is incoherent (start > end || end < start)
                disabled={
                    !!(period.start && !period.end) ||
                    !!(!period.start && period.end) ||
                    period.start > period.end ||
                    period.end < period.start
                }
                onClick={onClickModifyBusUnavailability}
            >
                <FormattedMessage id="confirm.default.confirm" />
            </Button>
        </DialogActions>
    );
});
