/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { AppUserDto } from '../models';
import { BusLoggedUserDto } from '../models';
import { UserProfilesUpdateDto } from '../models';
import { UserRolesUpdateDto } from '../models';
import { UserSettingsUpdateDto } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const BusUserManagementWebServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        assignUserProfiles: async (
            userProfilesUpdateDto: UserProfilesUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'userProfilesUpdateDto' is not null or undefined
            assertParamExists('assignUserProfiles', 'userProfilesUpdateDto', userProfilesUpdateDto);
            const localVarPath = `/users/profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                userProfilesUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        createUser: async (appUserDto: AppUserDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'appUserDto' is not null or undefined
            assertParamExists('createUser', 'appUserDto', appUserDto);
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(appUserDto, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        deleteUsers: async (requestBody: Array<string>, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'requestBody' is not null or undefined
            assertParamExists('deleteUsers', 'requestBody', requestBody);
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'DELETE',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(requestBody, localVarRequestOptions, configuration);
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getAllUsers: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/users`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        getUser: async (email: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'email' is not null or undefined
            assertParamExists('getUser', 'email', email);
            const localVarPath = `/user/{email}`.replace(`{${'email'}}`, encodeURIComponent(String(email)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateUserRoles: async (userRolesUpdateDto: UserRolesUpdateDto, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userRolesUpdateDto' is not null or undefined
            assertParamExists('updateUserRoles', 'userRolesUpdateDto', userRolesUpdateDto);
            const localVarPath = `/user/roles`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                userRolesUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateUserSettings: async (
            userSettingsUpdateDto: UserSettingsUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'userSettingsUpdateDto' is not null or undefined
            assertParamExists('updateUserSettings', 'userSettingsUpdateDto', userSettingsUpdateDto);
            const localVarPath = `/user/settings`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration);

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                userSettingsUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const BusUserManagementWebServicesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BusUserManagementWebServicesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {UserProfilesUpdateDto} userProfilesUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async assignUserProfiles(
            userProfilesUpdateDto: UserProfilesUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.assignUserProfiles(
                userProfilesUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {AppUserDto} appUserDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(
            appUserDto: AppUserDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(appUserDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUsers(
            requestBody: Array<string>,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUsers(requestBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllUsers(
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AppUserDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllUsers(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} email
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(
            email: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(email, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UserRolesUpdateDto} userRolesUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserRoles(
            userRolesUpdateDto: UserRolesUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AppUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserRoles(userRolesUpdateDto, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {UserSettingsUpdateDto} userSettingsUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserSettings(
            userSettingsUpdateDto: UserSettingsUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BusLoggedUserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserSettings(
                userSettingsUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const BusUserManagementWebServicesApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = BusUserManagementWebServicesApiFp(configuration);
    return {
        assignUserProfiles(
            userProfilesUpdateDto: UserProfilesUpdateDto,
            options?: any,
        ): AxiosPromise<Array<AppUserDto>> {
            return localVarFp
                .assignUserProfiles(userProfilesUpdateDto, options)
                .then(request => request(axios, basePath));
        },
        createUser(appUserDto: AppUserDto, options?: any): AxiosPromise<AppUserDto> {
            return localVarFp.createUser(appUserDto, options).then(request => request(axios, basePath));
        },
        deleteUsers(requestBody: Array<string>, options?: any): AxiosPromise<void> {
            return localVarFp.deleteUsers(requestBody, options).then(request => request(axios, basePath));
        },
        getAllUsers(options?: any): AxiosPromise<Array<AppUserDto>> {
            return localVarFp.getAllUsers(options).then(request => request(axios, basePath));
        },
        getUser(email: string, options?: any): AxiosPromise<AppUserDto> {
            return localVarFp.getUser(email, options).then(request => request(axios, basePath));
        },
        updateUserRoles(userRolesUpdateDto: UserRolesUpdateDto, options?: any): AxiosPromise<AppUserDto> {
            return localVarFp.updateUserRoles(userRolesUpdateDto, options).then(request => request(axios, basePath));
        },
        updateUserSettings(
            userSettingsUpdateDto: UserSettingsUpdateDto,
            options?: any,
        ): AxiosPromise<BusLoggedUserDto> {
            return localVarFp
                .updateUserSettings(userSettingsUpdateDto, options)
                .then(request => request(axios, basePath));
        },
    };
};
export interface BusUserManagementWebServicesApiAssignUserProfilesRequest {
    readonly userProfilesUpdateDto: UserProfilesUpdateDto;
}

export interface BusUserManagementWebServicesApiCreateUserRequest {
    readonly appUserDto: AppUserDto;
}

export interface BusUserManagementWebServicesApiDeleteUsersRequest {
    readonly requestBody: Array<string>;
}

export interface BusUserManagementWebServicesApiGetUserRequest {
    readonly email: string;
}

export interface BusUserManagementWebServicesApiUpdateUserRolesRequest {
    readonly userRolesUpdateDto: UserRolesUpdateDto;
}

export interface BusUserManagementWebServicesApiUpdateUserSettingsRequest {
    readonly userSettingsUpdateDto: UserSettingsUpdateDto;
}

export class BusUserManagementWebServicesApi extends BaseAPI {
    public assignUserProfiles(
        requestParameters: BusUserManagementWebServicesApiAssignUserProfilesRequest,
        options?: any,
    ) {
        return BusUserManagementWebServicesApiFp(this.configuration)
            .assignUserProfiles(requestParameters.userProfilesUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public createUser(requestParameters: BusUserManagementWebServicesApiCreateUserRequest, options?: any) {
        return BusUserManagementWebServicesApiFp(this.configuration)
            .createUser(requestParameters.appUserDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public deleteUsers(requestParameters: BusUserManagementWebServicesApiDeleteUsersRequest, options?: any) {
        return BusUserManagementWebServicesApiFp(this.configuration)
            .deleteUsers(requestParameters.requestBody, options)
            .then(request => request(this.axios, this.basePath));
    }
    public getAllUsers(options?: any) {
        return BusUserManagementWebServicesApiFp(this.configuration)
            .getAllUsers(options)
            .then(request => request(this.axios, this.basePath));
    }
    public getUser(requestParameters: BusUserManagementWebServicesApiGetUserRequest, options?: any) {
        return BusUserManagementWebServicesApiFp(this.configuration)
            .getUser(requestParameters.email, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateUserRoles(requestParameters: BusUserManagementWebServicesApiUpdateUserRolesRequest, options?: any) {
        return BusUserManagementWebServicesApiFp(this.configuration)
            .updateUserRoles(requestParameters.userRolesUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateUserSettings(
        requestParameters: BusUserManagementWebServicesApiUpdateUserSettingsRequest,
        options?: any,
    ) {
        return BusUserManagementWebServicesApiFp(this.configuration)
            .updateUserSettings(requestParameters.userSettingsUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type PatchAssignUserProfilesResponse = AxiosResponse<Array<AppUserDto>>;
export const KeyPatchAssignUserProfiles = 'PatchAssignUserProfiles';
export const useMutationPatchAssignUserProfiles = (
    options?: UseMutationOptions<
        PatchAssignUserProfilesResponse,
        AxiosError,
        BusUserManagementWebServicesApiAssignUserProfilesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchAssignUserProfilesResponse,
        AxiosError,
        BusUserManagementWebServicesApiAssignUserProfilesRequest
    >(
        (args: BusUserManagementWebServicesApiAssignUserProfilesRequest) =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken })).assignUserProfiles(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchAssignUserProfiles = (
    args: BusUserManagementWebServicesApiAssignUserProfilesRequest,
    options?: UseQueryOptions<PatchAssignUserProfilesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchAssignUserProfilesResponse, AxiosError>(
        [KeyPatchAssignUserProfiles, args, options && options.queryKey, accessToken],
        () =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken, ...conf })).assignUserProfiles(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostCreateUserResponse = AxiosResponse<AppUserDto>;
export const KeyPostCreateUser = 'PostCreateUser';
export const useMutationPostCreateUser = (
    options?: UseMutationOptions<PostCreateUserResponse, AxiosError, BusUserManagementWebServicesApiCreateUserRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<PostCreateUserResponse, AxiosError, BusUserManagementWebServicesApiCreateUserRequest>(
        (args: BusUserManagementWebServicesApiCreateUserRequest) =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken })).createUser(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostCreateUser = (
    args: BusUserManagementWebServicesApiCreateUserRequest,
    options?: UseQueryOptions<PostCreateUserResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PostCreateUserResponse, AxiosError>(
        [KeyPostCreateUser, args, options && options.queryKey, accessToken],
        () =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken, ...conf })).createUser(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type DeleteDeleteUsersResponse = AxiosResponse<void>;
export const KeyDeleteDeleteUsers = 'DeleteDeleteUsers';
export const useMutationDeleteDeleteUsers = (
    options?: UseMutationOptions<
        DeleteDeleteUsersResponse,
        AxiosError,
        BusUserManagementWebServicesApiDeleteUsersRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<DeleteDeleteUsersResponse, AxiosError, BusUserManagementWebServicesApiDeleteUsersRequest>(
        (args: BusUserManagementWebServicesApiDeleteUsersRequest) =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken })).deleteUsers(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryDeleteDeleteUsers = (
    args: BusUserManagementWebServicesApiDeleteUsersRequest,
    options?: UseQueryOptions<DeleteDeleteUsersResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<DeleteDeleteUsersResponse, AxiosError>(
        [KeyDeleteDeleteUsers, args, options && options.queryKey, accessToken],
        () =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken, ...conf })).deleteUsers(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetAllUsersResponse = AxiosResponse<Array<AppUserDto>>;
export const KeyGetGetAllUsers = 'GetGetAllUsers';
export const useMutationGetGetAllUsers = (
    options?: UseMutationOptions<GetGetAllUsersResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetAllUsersResponse, AxiosError>(
        () => new BusUserManagementWebServicesApi(new Configuration({ accessToken })).getAllUsers(axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetAllUsers = (
    options?: UseQueryOptions<GetGetAllUsersResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetAllUsersResponse, AxiosError>(
        [KeyGetGetAllUsers, options && options.queryKey, accessToken],
        () => new BusUserManagementWebServicesApi(new Configuration({ accessToken, ...conf })).getAllUsers(axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type GetGetUserResponse = AxiosResponse<AppUserDto>;
export const KeyGetGetUser = 'GetGetUser';
export const useMutationGetGetUser = (
    options?: UseMutationOptions<GetGetUserResponse, AxiosError, BusUserManagementWebServicesApiGetUserRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<GetGetUserResponse, AxiosError, BusUserManagementWebServicesApiGetUserRequest>(
        (args: BusUserManagementWebServicesApiGetUserRequest) =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken })).getUser(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetUser = (
    args: BusUserManagementWebServicesApiGetUserRequest,
    options?: UseQueryOptions<GetGetUserResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<GetGetUserResponse, AxiosError>(
        [KeyGetGetUser, args, options && options.queryKey, accessToken],
        () =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken, ...conf })).getUser(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchUpdateUserRolesResponse = AxiosResponse<AppUserDto>;
export const KeyPatchUpdateUserRoles = 'PatchUpdateUserRoles';
export const useMutationPatchUpdateUserRoles = (
    options?: UseMutationOptions<
        PatchUpdateUserRolesResponse,
        AxiosError,
        BusUserManagementWebServicesApiUpdateUserRolesRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchUpdateUserRolesResponse,
        AxiosError,
        BusUserManagementWebServicesApiUpdateUserRolesRequest
    >(
        (args: BusUserManagementWebServicesApiUpdateUserRolesRequest) =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken })).updateUserRoles(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchUpdateUserRoles = (
    args: BusUserManagementWebServicesApiUpdateUserRolesRequest,
    options?: UseQueryOptions<PatchUpdateUserRolesResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchUpdateUserRolesResponse, AxiosError>(
        [KeyPatchUpdateUserRoles, args, options && options.queryKey, accessToken],
        () =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken, ...conf })).updateUserRoles(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchUpdateUserSettingsResponse = AxiosResponse<BusLoggedUserDto>;
export const KeyPatchUpdateUserSettings = 'PatchUpdateUserSettings';
export const useMutationPatchUpdateUserSettings = (
    options?: UseMutationOptions<
        PatchUpdateUserSettingsResponse,
        AxiosError,
        BusUserManagementWebServicesApiUpdateUserSettingsRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useMutation<
        PatchUpdateUserSettingsResponse,
        AxiosError,
        BusUserManagementWebServicesApiUpdateUserSettingsRequest
    >(
        (args: BusUserManagementWebServicesApiUpdateUserSettingsRequest) =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken })).updateUserSettings(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchUpdateUserSettings = (
    args: BusUserManagementWebServicesApiUpdateUserSettingsRequest,
    options?: UseQueryOptions<PatchUpdateUserSettingsResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const accessToken = useAppAuthValue('authToken') || undefined;

    const res = useQuery<PatchUpdateUserSettingsResponse, AxiosError>(
        [KeyPatchUpdateUserSettings, args, options && options.queryKey, accessToken],
        () =>
            new BusUserManagementWebServicesApi(new Configuration({ accessToken, ...conf })).updateUserSettings(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
