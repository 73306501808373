import { Box, styled } from '@mui/material';
import { DatePicker } from '../../../../../../../components/form/DatePicker/DatePicker';
import { theme } from '../../../../../../../theme';
import { TimeField } from '../../../../../../../components/form/TimeField/TimeField';

export const StyledBusUnavailabilityPeriodDatePicker = styled(DatePicker)`
    min-height: 0 !important;
    height: 50px;
    & .Mui-focused {
        background-color: ${theme.palette.blue.dark} !important;
    }
    & .MuiFilledInput-root:hover {
        background-color: ${theme.palette.blue.dark} !important;
    }
`;

export const StyledBusUnavailabilityPeriodTimePicker = styled(TimeField)`
    margin-top: 5px;
    & .Mui-focused {
        background-color: ${theme.palette.blue.dark};
    }
    & .MuiFilledInput-root:hover {
        background-color: ${theme.palette.blue.dark};
    }
`;

export const StyledBusUnavailabilityPeriodDialogContentContainer = styled(Box)`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    & .MuiFormControl-root {
        min-height: 0 !important;
    }
    & .MuiInputLabel-root {
        display: none !important;
    }
`;
