import '@fontsource/roboto/100.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/saira-condensed/400.css';
import { clockPickerClasses } from '@mui/lab';
import { createTheme, darkScrollbar, ThemeOptions, toggleButtonClasses } from '@mui/material';
import deepmerge from '@mui/utils/deepmerge';

declare module '@mui/material/styles' {
    interface Palette {
        blue: {
            lightest: string;
            lighter: string;
            lightBg: string;
            menuBg: string;
            main: string;
            darkBg: string;
            dark: string;
            darker: string;
            darkest: string;
            contrastText: string;
        };
        arrival: {
            main: string;
        };
        departure: {
            main: string;
        };
    }
    interface PaletteOptions {
        blue: Palette['blue'];
        arrival: Palette['arrival'];
        departure: Palette['departure'];
    }
}

const baseTheme: ThemeOptions = {
    palette: {
        mode: 'dark',
        primary: {
            light: '#FFFFFF',
            main: '#0C456C',
            dark: '#082940',
            contrastText: '#fff',
        },
        secondary: {
            light: '#17558e',
            main: '#14517B',
            dark: '#14517B',
            contrastText: '#fff',
        },
        error: {
            light: '#e36d6d',
            main: '#DB4343',
            dark: '#c52626',
            contrastText: '#fff',
        },
        success: {
            light: '#81c784',
            main: '#4caf50',
            dark: '#388e3c',
            contrastText: '#fff',
        },
        warning: {
            light: '#fff2d1',
            main: '#FFB74D',
            dark: '#ffc312',
            contrastText: '#34495e',
        },
        info: {
            light: '#b7b7b7',
            main: '#9e9e9e',
            dark: '#848484',
            contrastText: '#fff',
        },
        background: {
            default: '#0C456C',
        },
        text: {
            primary: '#082940',
            secondary: '#FFFFFF',
            disabled: '#B6D2E5',
        },
        blue: {
            lightest: '#b6d2e5',
            lighter: '#106ebb',
            lightBg: '#14517B',
            menuBg: '#126dbb',
            main: '#106ebb',
            dark: '#082940',
            darker: '#0c528c',
            darkBg: '#0C456C',
            darkest: '#0a3b5d',
            contrastText: '#FFF',
        },
        arrival: {
            main: '#7986cb',
        },
        departure: {
            main: '#249F98',
        },
    },
    typography: {
        caption: {
            fontSize: '13px',
        },
        fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
        h1: {
            fontSize: '34px',
            fontWeight: '300',
            letterSpacing: '0',
            lineHeight: '40px',
        },
    },
    shadows: [
        ...createTheme({}).shadows.map((shadow, i) => (i === 24 ? '0 3px 75px 0 rgba(0, 0, 0, 0.5)' : shadow)),
    ] as any,
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                '*': {
                    scrollbarWidth: 'thin',
                },
                '*::-webkit-scrollbar': {
                    width: '13px',
                    height: '13px',
                },
                '@keyframes blink': {
                    from: { opacity: 1 },
                    to: { opacity: 0 },
                },
                html: {
                    boxSizing: 'border-box',
                },
                'input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active':
                    // Hack to prevent chrome autofill style changes on input fields
                    // https://css-tricks.com/snippets/css/change-autocomplete-styles-webkit-browsers/
                    {
                        transition: 'background-color 5000s ease-in-out 0s',
                    },
                '*, *:before, *:after': {
                    boxSizing: 'inherit',
                },
                'body, html, #root': {
                    margin: 0,
                    padding: 0,
                    width: '100%',
                    height: '100%',
                    fontFamily: 'Roboto, Arial, sans-serif',
                    overflow: 'hidden',
                    fontSize: '14px',
                    background: '#14517B',
                    ...darkScrollbar({ track: 'transparent', thumb: '#0d3a59', active: '#0b3756' }),
                },
                [`.${clockPickerClasses.arrowSwitcher} + div span[role=option]:not(.Mui-selected)`]: {
                    color: '#0C456C',
                },
            },
        },
        MuiButton: {
            defaultProps: {
                disableElevation: true,
            },
            styleOverrides: {
                containedPrimary: {
                    backgroundColor: '#006EBB',
                    color: '#FFFFFF',
                    '&:hover': {
                        backgroundColor: '#008ff5',
                    },
                },
                outlinedSecondary: {
                    borderColor: '#FFFFFF',
                    color: '#FFFFFF',
                    '&:hover': {
                        borderColor: '#FFFFFF80',
                    },
                },
                sizeLarge: {
                    fontFamily: 'Roboto',
                    fontSize: '15px',
                    fontWeight: '500',
                    letterSpacing: '0',
                    lineHeight: '18px',
                    borderRadius: '10px',
                    textTransform: 'none',
                    height: '50px',
                    minWidth: '50px',
                    padding: '16px 16px',
                },
            },
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    lineHeight: 'initial',
                    height: 36,
                    backgroundColor: '#14517B',
                    color: '#FFFFFF',
                    [`&.${toggleButtonClasses.selected}`]: {
                        backgroundColor: '#006EBB',
                        color: '#FFFFFF',
                    },
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                grouped: {
                    borderRadius: 5,
                },
            },
        },
    },
};

export const theme = createTheme(baseTheme);

export const lightTheme = createTheme(
    deepmerge(baseTheme, {
        palette: {
            mode: 'light',
            background: {
                default: '#fff',
            },
            primary: {
                main: '#fff',
                light: '#0C456C',
                dark: '#082940',
                contrastText: '#082940',
            },
            secondary: {
                light: '#fff',
                main: '#b6d2e5',
                dark: '#14517B',
                contrastText: '#fff',
            },
            text: {
                primary: '#082940',
                secondary: '#082940',
                disabled: '#0C456C60',
            },
            blue: {
                lightest: '#b6d2e5',
                lighter: '#106ebb',
                lightBg: '#106ebb', // '#14517B',
                main: '#106ebb',
                dark: '#082940',
                darker: '#0c528c',
                darkBg: '#0C456C',
                darkest: '#0a3b5d',
                contrastText: '#FFF',
            },
        },
    }),
);
