import { Box, styled } from '@mui/material';
import React, { memo, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ROUTE_DISPATCHER, ROUTE_DRIVER, ROUTE_HELP, ROUTE_SETTINGS } from '../../../routes';
import { ReactComponent as ClosePopup } from '../../../assets/icons/close-popup.svg';
import { ReactComponent as LogoutIcon } from '../../../assets/icons/icon-logout.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/icon-settings.svg';
import { ReactComponent as BusIcon } from '../../../assets/icons/icon-bus.svg';
import { useAuth, useCurrentUser } from '../../../contexts/AuthContext.selectors';
import { HelpIcon } from '../../icons/HelpIcon';
import { ProfileType } from '../../../hooks/useAuthorizeProfile';

const MenuHeader = styled('div')`
    height: 90px;
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 33px 26px;
    font-family: Roboto, serif;
    font-size: 28px;
    font-weight: 300;
    line-height: 33px;
    border-bottom: 1px solid ${({ theme }) => theme.palette.blue.darkest};
`;

const MenuContent = styled('div')`
    font-family: Roboto, serif;
    font-size: 20px;
    font-weight: 300;
    letter-spacing: 0;
`;

const MenuItem = styled('div')<{ selected?: boolean }>`
    display: flex;
    align-items: center;
    padding: 10px 25px;
    height: 72px;
    cursor: pointer;
    box-sizing: content-box;
    ${({ selected }) => (selected ? 'background: #ffffff30;' : '')}
`;

const IconContainer = styled('div')`
    margin-right: 15px;
`;

const StyledMenu = styled('div')`
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background: ${({ theme }) => theme.palette.blue.menuBg};
`;

export const Menu = memo(({ closeMenu }: { closeMenu?: () => void }) => {
    const navigate = useNavigate();
    const currentUser = useCurrentUser();
    const logout = useAuth('logout');
    const location = useLocation();
    const userHomeRoute = useMemo(() => {
        return currentUser.profile.name === ProfileType.DISPATCHER ? ROUTE_DISPATCHER : ROUTE_DRIVER;
    }, [currentUser.profile.name]);

    return (
        <StyledMenu>
            <MenuHeader>
                <Box>
                    {currentUser?.firstName}&nbsp;{currentUser?.lastName}
                </Box>
                <Box display="flex" alignItems="center" sx={{ cursor: 'pointer' }}>
                    <ClosePopup onClick={closeMenu} stroke={'#FFFFFF'} />
                </Box>
            </MenuHeader>
            <MenuContent onClick={closeMenu}>
                <MenuItem
                    onClick={() => navigate(userHomeRoute)}
                    selected={!!matchPath(userHomeRoute, location.pathname)}
                >
                    <IconContainer>
                        <BusIcon width="38" height="38" color="white" />
                    </IconContainer>
                    <Box>
                        <FormattedMessage id="home" />
                    </Box>
                </MenuItem>
                <MenuItem
                    onClick={() => navigate(ROUTE_SETTINGS)}
                    selected={!!matchPath(ROUTE_SETTINGS, location.pathname)}
                >
                    <IconContainer>
                        <SettingsIcon width="38" height="38" color="white" />
                    </IconContainer>
                    <FormattedMessage id="menu.settings" />
                </MenuItem>
                <MenuItem onClick={() => navigate(ROUTE_HELP)} selected={!!matchPath(ROUTE_HELP, location.pathname)}>
                    <IconContainer>
                        <HelpIcon size={38} />
                    </IconContainer>
                    <FormattedMessage id="menu.help" />
                </MenuItem>
                <MenuItem onClick={logout}>
                    <IconContainer>
                        <LogoutIcon width="38" height="38" color="white" />
                    </IconContainer>
                    <FormattedMessage id="menu.logout" />
                </MenuItem>
            </MenuContent>
        </StyledMenu>
    );
});
