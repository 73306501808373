import { Box, styled } from '@mui/material';
import { theme } from '../../../../theme';

export const zoneTooltipContentsStyles = {
    outline: `1px dashed ${theme.palette.arrival.main}`,
    borderRadius: '4px',
    padding: '0 2px',
};

export const StyledContainer = styled(Box)`
    position: relative;
    font-size: 12px;
    color: white;
    display: flex;
    align-items: center;
`;

export const StyledAboveStripContainer = styled(Box)`
    display: flex;
    justify-content: space-between;
    z-index: 3;
`;

export const StyledStripTimeBadge = styled(Box)`
    border-radius: 3px;
    width: fit-content;
    height: fit-content;
    font-weight: 900;
    padding: 1px 4px;
    margin-left: 10px;
    margin-bottom: 5px;
    z-index: 2;
`;

export const StyledStripFlow = styled(Box)`
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0;
    z-index: 2;
`;

export const StyledStripFlightBox = styled(Box)`
    height: 60px;
    width: 140px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 0 15px;
    color: black;
    display: flex;
    gap: 3px;
    flex-direction: column;
    justify-content: center;
    position: relative;
    z-index: 2;
`;

export const StyledDirections = styled(Box)`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

export const StyledAcPaxInfos = styled(Box)`
    width: 100%;
    display: flex;
    justify-content: space-between;
`;

export const StyledAcPaxInfo = styled(Box)`
    display: flex;
    align-items: center;
`;

export const StyledStripFooterContainer = styled(Box)`
    width: 140px;
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
`;

export const StyledStripFooter = styled(Box)`
    width: 120px;
    display: flex;
    justify-content: space-between;
    margin-top: 2px;
`;

export const StyledMissionStepsContainer = styled(Box)`
    display: flex;
    gap: 3px;
    margin-left: 6px;
    padding-top: 4px;
    position: relative;
    z-index: 2;
`;
