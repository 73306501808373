import React, { memo, useMemo } from 'react';
import { FlightFlow, MissionStepDto } from '../../../../../backend/gen';
import { Box } from '@mui/material';
import { Locales, useIntlValue } from '@innovatm/js-iam';
import {
    StyledArrStepCompleteIcon,
    StyledDepStepCompleteIcon,
    StyledStepContainer,
    StyledStepPendingCheckbox,
} from './StepsPreview.style';
import { getFlowColor } from '../../../../../utils/data.utils';

export const StepsPreview = memo(function StepsPreview({ flow, steps }: { flow: FlightFlow; steps: MissionStepDto[] }) {
    const locale = useIntlValue('locale');
    const currentStep = useMemo(() => {
        const indexOfFirstIncompleteStep = steps.findIndex(step => step.done === false);
        return indexOfFirstIncompleteStep === 0 ? 0 : indexOfFirstIncompleteStep - 1;
    }, [steps]);
    const flowColor = getFlowColor(flow);
    const stepCompleteIcon = flow === FlightFlow.ARR ? <StyledArrStepCompleteIcon /> : <StyledDepStepCompleteIcon />;

    return (
        <>
            {steps.map((step, i) => {
                return (
                    <StyledStepContainer key={i} color={step.done ? 'black' : '#ACACAC'}>
                        {step.done ? stepCompleteIcon : <StyledStepPendingCheckbox />}
                        <Box color={i === currentStep && (i > 0 || step.done) ? flowColor : ''}>
                            {locale === Locales.en ? step.descriptionEng : step.descriptionFr}
                        </Box>
                    </StyledStepContainer>
                );
            })}
        </>
    );
});
