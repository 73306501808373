import React, { memo } from 'react';
import { CustomMenuButton } from '../../../../../components/CustomMenu/CustomMenuButton';
import { CustomMenuStyles } from '../../../../../components/CustomMenu/CustomMenu.style';
import { PopoverMenu } from '../../../../../components/navigation/PopoverMenu/PopoverMenu';
import { FormattedMessage } from 'react-intl';
import { MenuItem } from '@mui/material';

export const BusGanttMenu = memo(function BusGanttMenu({
    onClickOpenUnavailabilityPeriodDialog,
}: {
    onClickOpenUnavailabilityPeriodDialog: () => void;
}) {
    return (
        <>
            <PopoverMenu
                renderButton={({ onClick }) => <CustomMenuButton onClick={onClick} />}
                menuProps={{
                    anchorOrigin: { vertical: 'top', horizontal: 'right' },
                    transformOrigin: { vertical: 'top', horizontal: 'left' },
                    disableAutoFocusItem: true,
                }}
                menuStyles={CustomMenuStyles}
            >
                {({ close }) => [
                    <MenuItem
                        key={1}
                        onClick={() => {
                            onClickOpenUnavailabilityPeriodDialog();
                            close();
                        }}
                    >
                        <FormattedMessage id={'dispatcher.ganttBusMenu.defineUnavailability'} />
                    </MenuItem>,
                ]}
            </PopoverMenu>
        </>
    );
});
