import { useNavigate } from 'react-router-dom';
import { ROUTE_HOME } from '../routes';
import { useEffect } from 'react';
import { BusLoggedUserDto } from '../backend/gen';

export enum ProfileType {
    'DISPATCHER' = 'dispatcher',
    'DRIVER' = 'driver',
}

export const useAuthorizeProfile = (currentUser: BusLoggedUserDto, expectedProfile: ProfileType) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (currentUser.profile.name !== expectedProfile) {
            navigate(ROUTE_HOME);
        }
    }, [currentUser.profile.name, expectedProfile, navigate]);
};
