/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { FlightInfoDto } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const PaxBusRestControllerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        getFlightInfo: async (token: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('getFlightInfo', 'token', token);
            const localVarPath = `/paxbus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'GET',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const PaxBusRestControllerApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = PaxBusRestControllerApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} token
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFlightInfo(
            token: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FlightInfoDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFlightInfo(token, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const PaxBusRestControllerApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = PaxBusRestControllerApiFp(configuration);
    return {
        getFlightInfo(token: string, options?: any): AxiosPromise<FlightInfoDto> {
            return localVarFp.getFlightInfo(token, options).then(request => request(axios, basePath));
        },
    };
};
export interface PaxBusRestControllerApiGetFlightInfoRequest {
    readonly token: string;
}

export class PaxBusRestControllerApi extends BaseAPI {
    public getFlightInfo(requestParameters: PaxBusRestControllerApiGetFlightInfoRequest, options?: any) {
        return PaxBusRestControllerApiFp(this.configuration)
            .getFlightInfo(requestParameters.token, options)
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type GetGetFlightInfoResponse = AxiosResponse<FlightInfoDto>;
export const KeyGetGetFlightInfo = 'GetGetFlightInfo';
export const useMutationGetGetFlightInfo = (
    options?: UseMutationOptions<GetGetFlightInfoResponse, AxiosError, PaxBusRestControllerApiGetFlightInfoRequest>,
    axiosConfig?: AxiosRequestConfig,
) => {
    const res = useMutation<GetGetFlightInfoResponse, AxiosError, PaxBusRestControllerApiGetFlightInfoRequest>(
        (args: PaxBusRestControllerApiGetFlightInfoRequest) =>
            new PaxBusRestControllerApi(new Configuration()).getFlightInfo(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryGetGetFlightInfo = (
    args: PaxBusRestControllerApiGetFlightInfoRequest,
    options?: UseQueryOptions<GetGetFlightInfoResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const res = useQuery<GetGetFlightInfoResponse, AxiosError>(
        [KeyGetGetFlightInfo, args, options && options.queryKey],
        () => new PaxBusRestControllerApi(new Configuration({ ...conf })).getFlightInfo(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
