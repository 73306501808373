import React, { memo, useCallback } from 'react';
import {
    Header,
    LeftComponentsHeader,
    MiddleComponentsHeader,
    RightComponentsHeader,
} from '../../../../components/navigation/Header/Header';
import { Nav } from '../../../../components/navigation/Nav/Nav';
import { Clock } from '../../../../components/navigation/ToggleUTCLocal/Clock';
import { ReactComponent as BusIcon } from '../../../../assets/icons/icon-bus.svg';
import { StyledBusContainer, StyledBusName } from './DriverMissionsPageHeader.style';
import { useNavigate } from 'react-router-dom';
import { ROUTE_DRIVER } from '../../../../routes';

export const DriverMissionsPageHeader = memo(function DriverMissionsPageHeader({ bus }: { bus: string }) {
    const navigate = useNavigate();
    const navigateToBusSelection = useCallback(() => {
        navigate(ROUTE_DRIVER);
    }, [navigate]);

    return (
        <Header>
            <LeftComponentsHeader>
                <Nav />
            </LeftComponentsHeader>
            <MiddleComponentsHeader>
                <Clock />
            </MiddleComponentsHeader>
            <RightComponentsHeader>
                <StyledBusContainer onClick={navigateToBusSelection}>
                    <StyledBusName>{bus}</StyledBusName>
                    <BusIcon />
                </StyledBusContainer>
            </RightComponentsHeader>
        </Header>
    );
});
