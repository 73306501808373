export const ROUTE_HOME = '/';
export const ROUTE_LOGIN = '/login';
export const ROUTE_SIGNUP = '/signup';
export const ROUTE_FIRSTCONNECTION = '/firstConnection';
export const ROUTE_FORGOTPASSWORD = '/forgotPassword';
export const ROUTE_RESETPASSWORD = '/resetPassword';
export const ROUTE_UNLOCKACCOUNT = '/unlockAccount';
export const ROUTE_BUS = '/display';
export const ROUTE_DRIVER = '/driver';
export const ROUTE_DRIVER_MISSIONS = '/driver/mission/:busNumber';
export const ROUTE_DISPATCHER = '/dispatcher';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_HELP = '/help';
