import React, { memo } from 'react';
import {
    Header,
    LeftComponentsHeader,
    MiddleComponentsHeader,
    RightComponentsHeader,
} from '../navigation/Header/Header';
import { Nav } from '../navigation/Nav/Nav';
import { Clock } from '../navigation/ToggleUTCLocal/Clock';

export const HeaderComponent = memo(function HeaderComponent() {
    return (
        <Header>
            <LeftComponentsHeader>
                <Nav />
            </LeftComponentsHeader>
            <MiddleComponentsHeader>
                <Clock />
            </MiddleComponentsHeader>
            <RightComponentsHeader />
        </Header>
    );
});
