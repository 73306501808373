import React, { memo } from 'react';
import { DispatcherMissionSlotDto, FlightFlow, FlightSlotDto, MissionState } from '../../../../backend/gen';
import {
    StyledAboveStripContainer,
    StyledAcPaxInfo,
    StyledAcPaxInfos,
    StyledContainer,
    StyledDirections,
    StyledMissionStepsContainer,
    StyledStripFlightBox,
    StyledStripFlow,
    StyledStripFooter,
    StyledStripFooterContainer,
    StyledStripTimeBadge,
    zoneTooltipContentsStyles,
} from './StripFlight.style';
import { FormattedTime } from '../../../../components/time/FormattedTime';
import { Box, styled, Tooltip, tooltipClasses, TooltipProps, Zoom } from '@mui/material';
import { ReactComponent as AirlineSeatReclineExtraIcon } from '../../../../assets/icons/icon-passenger-light-bus.svg';
import { ReactComponent as AircraftIcon } from '../../../../assets/icons/icon-plane-light-bus.svg';
import { StepsIndicator } from './StripFlightMissionSteps/StepsIndicator';
import { useBoolean } from '../../../../hooks/useBoolean';
import { StripFlightMenu } from './StripFlightMenu/StripFlightMenu';
import { StepsPreview } from './StripFlightMissionSteps/StepsPreview';
import { getFlowColor } from '../../../../utils/data.utils';
import { theme } from '../../../../theme';
import { SpecifyDropoffLocationDialog } from '../Gantt/SpecifyDropoffLocationDialog/SpecifyDropoffLocationDialog';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        color: 'black',
        backgroundColor: 'white',
        borderRadius: '5px',
        boxShadow: '0 3px 4px 0 rgba(0,0,0,0.14), 0 3px 3px -2px rgba(0,0,0,0.12), 0 1px 8px 0 rgba(0,0,0,0.2)',
        fontSize: '11px',
        fontWeight: 'bold',
        letterSpacing: 0,
        lineHeight: '22px',
        padding: '10px',
    },
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.arrival.main,
    },
}));

export const StripFlight = memo(function StripFlight({ flight }: { flight: FlightSlotDto | DispatcherMissionSlotDto }) {
    const hasMission = 'state' in flight;
    const showMenuButton = useBoolean(false);
    const flowColor = getFlowColor(flight.flow);
    const isMissionTerminated = hasMission && flight.state === MissionState.TERMINATED;
    const specifyDropoffLocationDialogOpen = useBoolean(false);

    return (
        <StyledContainer>
            <SpecifyDropoffLocationDialog
                flightId={flight.flightId}
                dropoffLocation={flight.dropOffLocation}
                open={specifyDropoffLocationDialogOpen.value}
                closeDialog={specifyDropoffLocationDialogOpen.setFalse}
            />
            <Box
                onMouseEnter={isMissionTerminated ? null : showMenuButton.setTrue}
                onMouseLeave={isMissionTerminated ? null : showMenuButton.setFalse}
            >
                <StyledAboveStripContainer>
                    <StyledStripTimeBadge bgcolor={flowColor} color={isMissionTerminated ? '#cccccc' : 'white'}>
                        <FormattedTime>{flight.refTime}</FormattedTime>
                    </StyledStripTimeBadge>
                    {showMenuButton.value && <StyledStripFlow color={flowColor}>{flight.flow}</StyledStripFlow>}
                    <StripFlightMenu
                        showMenuButton={showMenuButton}
                        flightId={flight.flightId}
                        flightFlow={flight.flow}
                        flightNbTours={hasMission ? flight.nbTours : null}
                        missionId={hasMission ? flight.missionId : null}
                        flightIsManual={flight.manual}
                        onClickOpenSpecifyDropoffLocationDialog={specifyDropoffLocationDialogOpen.setTrue}
                    />
                </StyledAboveStripContainer>
                <StyledStripFlightBox
                    sx={{
                        backgroundColor: isMissionTerminated ? '#cccccc' : 'white',
                        outline:
                            hasMission && flight.state === MissionState.IN_PROGRESS ? `3px solid ${flowColor}` : 'none',
                    }}
                >
                    <StyledDirections>
                        {flight.flow === FlightFlow.ARR ? (
                            <>
                                <Box>
                                    Pkg <b>{flight.parking}</b>
                                </Box>
                                <Box>→</Box>
                                <StyledTooltip
                                    title={flight?.dropOffLocation || ''}
                                    TransitionComponent={Zoom}
                                    placement={'bottom'}
                                    arrow
                                >
                                    <Box sx={flight.dropOffLocation?.length ? zoneTooltipContentsStyles : undefined}>
                                        Zone <b>{flight.zone}</b>
                                    </Box>
                                </StyledTooltip>
                            </>
                        ) : (
                            <>
                                <Box>
                                    Gate <b>{flight.gate}</b>
                                </Box>
                                <Box>→</Box>
                                <Box>
                                    Pkg <b>{flight.parking}</b>
                                </Box>
                            </>
                        )}
                    </StyledDirections>
                    <StyledAcPaxInfos>
                        <StyledAcPaxInfo>
                            <AircraftIcon fill={flowColor} width={'24px'} height={'24px'} />
                            {flight.acType.iata}
                        </StyledAcPaxInfo>
                        <StyledAcPaxInfo>
                            <AirlineSeatReclineExtraIcon fill={flowColor} width={'24px'} height={'24px'} />
                            {flight.pax}
                        </StyledAcPaxInfo>
                    </StyledAcPaxInfos>
                </StyledStripFlightBox>
                <StyledStripFooterContainer sx={{ opacity: isMissionTerminated ? '50%' : '100%' }}>
                    <StyledStripFooter>
                        <Box>{flight.flightNumber}</Box>
                        <Box>{flight.adepAdes.iata}</Box>
                    </StyledStripFooter>
                </StyledStripFooterContainer>
            </Box>
            {hasMission && (
                <StyledTooltip
                    title={<StepsPreview steps={flight.steps} flow={flight.flow} />}
                    followCursor={true}
                    TransitionComponent={Zoom}
                >
                    <StyledMissionStepsContainer>
                        {[...Array(flight.nbTours)].map((_, i) => {
                            return (
                                <StepsIndicator
                                    key={i}
                                    tour={i + 1}
                                    nbTours={flight.nbTours}
                                    stepsTotal={flight.nbStepsTotal}
                                    stepsDone={flight.nbStepsDone}
                                    flow={flight.flow}
                                />
                            );
                        })}
                    </StyledMissionStepsContainer>
                </StyledTooltip>
            )}
        </StyledContainer>
    );
});
