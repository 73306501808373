import { Box, styled } from '@mui/material';
import { ganttFlightRowHeight } from '../gantt.constants';
import { theme } from '../../../../../theme';

export const StyledBusUnavailabilityPeriodGanttRow = styled(Box)`
    position: absolute;
    top: 15px;
    min-height: ${ganttFlightRowHeight}px;
    height: calc(100% + 20px);
    opacity: 0.4;
    background: repeating-linear-gradient(
        -45deg,
        ${theme.palette.blue.lightest},
        ${theme.palette.blue.lightest} 2px,
        transparent 2px,
        transparent 7px
    );
`;
