import { AuthState, createAuthentication } from '@innovatm/js-iam';
import { BusLoggedUserDto } from '../backend/gen';
import { useContextSelector } from 'use-context-selector';

export const { AuthContext, useAuth, AuthProvider } = createAuthentication<BusLoggedUserDto>();

/**
 * selectors
 */
export const selectCurrentUser = (state: AuthState<BusLoggedUserDto>) => state?.currentUser;
export const selectCurrentUserClient = (state: AuthState<BusLoggedUserDto>) => state?.currentUser?.client;
export const selectCurrentUserTimezone = (state: AuthState<BusLoggedUserDto>) => state?.currentUser?.client?.timeZoneId;

/**
 * selector hooks
 */
export const useCurrentUser = () => useContextSelector(AuthContext, selectCurrentUser);
export const useCurrentUserClient = () => useContextSelector(AuthContext, selectCurrentUserClient);
export const useCurrentUserClientTimezone = () => useContextSelector(AuthContext, selectCurrentUserTimezone);
