import { ComponentProps, memo } from 'react';
import { AUTH_TOKEN_AK_SCHEDULE_STORAGE_KEY, RENEW_TOKEN_AK_SCHEDULE_STORAGE_KEY } from '../utils/constants';
import { globalAxios } from '../backend/gen/common';
import { AuthProvider } from './AuthContext.selectors';

const loginAs = window['location'].pathname.includes('login-as');

export const AuthContextProvider = memo((props: Partial<ComponentProps<typeof AuthProvider>>) => {
    return (
        <AuthProvider
            autoLogin={!loginAs}
            globalAxios={globalAxios}
            authTokenStorageKey={AUTH_TOKEN_AK_SCHEDULE_STORAGE_KEY}
            renewTokenStorageKey={RENEW_TOKEN_AK_SCHEDULE_STORAGE_KEY}
            renewTokenHttpCode={401}
            {...props}
        >
            {props.children}
        </AuthProvider>
    );
});
