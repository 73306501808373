/*
 * ******************************************************************************
 * Copyright Innov'ATM all rights reserved. This software is the property of
 * Innov'ATM and may not be used in any manner except under a license agreement
 * signed with Innov'ATM.
 * *******************************************************************************
 */
import { Box, styled } from '@mui/material';
import { memo } from 'react';
import { HALF_HOUR } from '../../../../utils/constants';
import { ganttPxPerMs } from './gantt.constants';

export const GanttGridBox = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    width: '100%',
    maxHeight: '15px',
    flex: 1,
    pointerEvents: 'none',
    zIndex: 0,
});
export const GanttGridStepBox = styled(Box)({
    display: 'flex',
    width: HALF_HOUR * ganttPxPerMs,
    height: '100%',
    flexShrink: 0,
    borderLeft: '1px solid #B6D2E580',
    '&:last-of-type': {
        borderRight: '1px solid #B6D2E580',
    },
});
export const GanttGridSubStepBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    width: '100%',
    height: '100%',
    flex: 1,
    '&:not(:first-of-type)': {
        borderLeft: `1px solid ${theme.palette.primary.dark}`,
    },
}));

export const GanttGrid = memo(({ from, to }: { from: number; to: number }) => {
    const halfHours = Array(Math.ceil((to - from) / HALF_HOUR))
        .fill(null)
        .map((_, i) => from + i * HALF_HOUR);

    return (
        <GanttGridBox>
            {halfHours.map(halfHour => (
                <GanttGridStepBox key={halfHour}>
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                    <GanttGridSubStepBox />
                </GanttGridStepBox>
            ))}
        </GanttGridBox>
    );
});
