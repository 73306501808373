import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useCurrentUser } from '../../../contexts/AuthContext.selectors';
import { ProfileType, useAuthorizeProfile } from '../../../hooks/useAuthorizeProfile';
import { useQueryGetGetAllMissions } from '../../../backend/gen';
import { HeaderComponent } from '../../../components/HeaderComponent/HeaderComponent';
import { differenceInMilliseconds, endOfDay, startOfDay } from 'date-fns';
import {
    DispatcherPageContentContainer,
    StyledAutoScrollFab,
    StyledCurrentTimeIndicator,
    StyledGanttContainer,
} from './DispatcherPage.style';
import { Gantt } from './Gantt/Gantt';
import { theme } from '../../../theme';
import { ganttContentPaddingLeft, ganttPxPerMs, ganttTitleWidth } from './Gantt/gantt.constants';
import { FormattedMessage } from 'react-intl';
import { toast } from 'react-toastify';
import { errorToastConfig } from '../../../utils/constants';

export const DispatcherPage = memo(function DispatcherPage() {
    const currentUser = useCurrentUser();
    useAuthorizeProfile(currentUser, ProfileType.DISPATCHER);

    const [shallAutoScroll, setShallAutoScroll] = useState(true);
    const [ganttScrollYOffset, setGanttScrollYOffset] = useState(0);
    const [now, setNow] = useState(Date.now());
    const dateNowRef = useRef<NodeJS.Timer>();
    const ganttRef = useRef<HTMLDivElement>(null);
    const currentTimeIndicatorRef = useRef<HTMLDivElement>(null);
    const from = startOfDay(Date.now()).getTime() - 3600 * 1000 * 2;
    const to = endOfDay(Date.now() + 3600 * 1000 * 24).getTime();

    // Calculate y offset on scroll to position vertical current time line
    const ganttContainerElement = ganttRef.current;
    useEffect(() => {
        const onScroll = () => setGanttScrollYOffset(ganttRef.current.scrollTop);
        if (ganttContainerElement) {
            ganttContainerElement.addEventListener('scroll', onScroll, { passive: true });
            return () => ganttContainerElement.removeEventListener('scroll', onScroll);
        }
    }, [ganttContainerElement]);

    // Update current time every second and convert time to px for use as current time indicator's left offset in px
    useEffect(() => {
        dateNowRef.current = setInterval(() => setNow(Date.now()), 1000);
        return () => {
            clearInterval(dateNowRef.current);
        };
    }, [dateNowRef]);
    const currentTimeInPx = useMemo(() => {
        return (differenceInMilliseconds(now, from) * ganttPxPerMs + ganttTitleWidth + ganttContentPaddingLeft).toFixed(
            0,
        );
    }, [from, now]);

    // Disable auto scroll when user scrolls manually (listening to wheel event)
    const disableAutoScroll = useCallback(() => {
        setShallAutoScroll(false);
    }, []);
    const enableAutoScroll = useCallback(() => {
        setShallAutoScroll(true);
    }, []);
    useEffect(() => {
        window.addEventListener('wheel', disableAutoScroll, { capture: true });
        return () => {
            window.removeEventListener('wheel', disableAutoScroll, { capture: true });
        };
    }, [disableAutoScroll]);

    // if shallAutoScroll, scrollIntoView on current time indicator element whenever currentTimeInPx is updated
    const currentTimeIndicatorElement = currentTimeIndicatorRef.current;
    useEffect(() => {
        if (currentTimeIndicatorElement && shallAutoScroll) {
            currentTimeIndicatorElement.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
        }
    }, [currentTimeIndicatorElement, shallAutoScroll]);

    const { data, status } = useQueryGetGetAllMissions({
        onError: () => toast(<FormattedMessage id={'bus.error.getMissions'} />, errorToastConfig),
        refetchInterval: 4000,
    });

    if (status === 'error' || !data) {
        return <div> Failed to fetch dispatcher missions </div>;
    }

    return (
        <>
            <HeaderComponent />
            <StyledAutoScrollFab
                variant="extended"
                disableRipple={true}
                color={shallAutoScroll ? 'success' : 'info'}
                onClick={shallAutoScroll ? disableAutoScroll : enableAutoScroll}
            >
                <FormattedMessage id={shallAutoScroll ? 'autoscrollEnabled' : 'autoscrollDisabled'} />
            </StyledAutoScrollFab>
            <DispatcherPageContentContainer ref={ganttRef}>
                <StyledCurrentTimeIndicator
                    left={`${currentTimeInPx}px`}
                    top={`${ganttScrollYOffset}px`}
                    ref={currentTimeIndicatorRef}
                />
                <StyledGanttContainer>
                    <Gantt
                        data={data.unallocated}
                        doNotNeedBus={data.doNotNeedBus}
                        from={from}
                        to={to}
                        titleBgColor={theme.palette.blue.lightBg}
                    />
                </StyledGanttContainer>
                {data.buses.map((bus, i) => {
                    return (
                        <StyledGanttContainer key={i} bgcolor={theme.palette.primary.main}>
                            <Gantt
                                data={data.allocations.find(allocations => allocations.bus === bus.name)?.missions}
                                from={from}
                                to={to}
                                bus={bus}
                                titleBgColor={theme.palette.primary.main}
                            />
                        </StyledGanttContainer>
                    );
                })}
            </DispatcherPageContentContainer>
        </>
    );
});
