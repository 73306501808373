/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * Copyright Innov'ATM all rights reserved. This software is the property of Innov'ATM and may not be used in any
 * manner except under a license agreement signed with Innov'ATM.
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { UserSettingsUpdateDto } from '../models';
import { Configuration, ConfigurationParameters } from '../configuration';
import { BASE_PATH, BaseAPI, RequestArgs } from '../base';
import { AxiosInstance, AxiosPromise, AxiosResponse, AxiosError, AxiosRequestConfig } from 'axios';
import { useQuery, UseMutationOptions, useMutation, UseQueryOptions } from 'react-query';
import {
    assertParamExists,
    createRequestFunction,
    DUMMY_BASE_URL,
    serializeDataIfNeeded,
    setSearchParams,
    toPathString,
    globalAxios,
    setBearerAuthToObject,
} from '../common';
import { useAppAuthValue } from '../../utils';
export const BusUserConnectionWebServicesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        resetForgottenPassword: async (token: string, password: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('resetForgottenPassword', 'token', token);
            // verify required parameter 'password' is not null or undefined
            assertParamExists('resetForgottenPassword', 'password', password);
            const localVarPath = `/user/resetPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }
            if (password !== undefined) {
                localVarQueryParameter['password'] = password;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        sendNotificationOnForgottenPassword: async (userEmail: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'userEmail' is not null or undefined
            assertParamExists('sendNotificationOnForgottenPassword', 'userEmail', userEmail);
            const localVarPath = `/user/forgottenPassword`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'POST',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (userEmail !== undefined) {
                localVarQueryParameter['userEmail'] = userEmail;
            }

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        updateUserOnFirstConnection: async (
            token: string,
            userSettingsUpdateDto: UserSettingsUpdateDto,
            options: any = {},
        ): Promise<RequestArgs> => {
            // verify required parameter 'token' is not null or undefined
            assertParamExists('updateUserOnFirstConnection', 'token', token);
            // verify required parameter 'userSettingsUpdateDto' is not null or undefined
            assertParamExists('updateUserOnFirstConnection', 'userSettingsUpdateDto', userSettingsUpdateDto);
            const localVarPath = `/user/firstConnection`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = {
                method: 'PATCH',
                ...baseOptions,
                ...options,
            };
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            if (token !== undefined) {
                localVarQueryParameter['token'] = token;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            const headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {
                ...localVarHeaderParameter,
                ...headersFromBaseOptions,
                ...options.headers,
            };
            localVarRequestOptions.data = serializeDataIfNeeded(
                userSettingsUpdateDto,
                localVarRequestOptions,
                configuration,
            );
            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
export const BusUserConnectionWebServicesApiFp = function (configuration?: Configuration) {
    const localVarAxiosParamCreator = BusUserConnectionWebServicesApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {string} token
         * @param {string} password
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async resetForgottenPassword(
            token: string,
            password: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.resetForgottenPassword(token, password, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} userEmail
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async sendNotificationOnForgottenPassword(
            userEmail: string,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.sendNotificationOnForgottenPassword(
                userEmail,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         *
         * @param {string} token
         * @param {UserSettingsUpdateDto} userSettingsUpdateDto
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateUserOnFirstConnection(
            token: string,
            userSettingsUpdateDto: UserSettingsUpdateDto,
            options?: any,
        ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateUserOnFirstConnection(
                token,
                userSettingsUpdateDto,
                options,
            );
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    };
};

export const BusUserConnectionWebServicesApiFactory = function (
    configuration?: Configuration,
    basePath?: string,
    axios?: AxiosInstance,
) {
    const localVarFp = BusUserConnectionWebServicesApiFp(configuration);
    return {
        resetForgottenPassword(token: string, password: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .resetForgottenPassword(token, password, options)
                .then(request => request(axios, basePath));
        },
        sendNotificationOnForgottenPassword(userEmail: string, options?: any): AxiosPromise<void> {
            return localVarFp
                .sendNotificationOnForgottenPassword(userEmail, options)
                .then(request => request(axios, basePath));
        },
        updateUserOnFirstConnection(
            token: string,
            userSettingsUpdateDto: UserSettingsUpdateDto,
            options?: any,
        ): AxiosPromise<void> {
            return localVarFp
                .updateUserOnFirstConnection(token, userSettingsUpdateDto, options)
                .then(request => request(axios, basePath));
        },
    };
};
export interface BusUserConnectionWebServicesApiResetForgottenPasswordRequest {
    readonly token: string;

    readonly password: string;
}

export interface BusUserConnectionWebServicesApiSendNotificationOnForgottenPasswordRequest {
    readonly userEmail: string;
}

export interface BusUserConnectionWebServicesApiUpdateUserOnFirstConnectionRequest {
    readonly token: string;

    readonly userSettingsUpdateDto: UserSettingsUpdateDto;
}

export class BusUserConnectionWebServicesApi extends BaseAPI {
    public resetForgottenPassword(
        requestParameters: BusUserConnectionWebServicesApiResetForgottenPasswordRequest,
        options?: any,
    ) {
        return BusUserConnectionWebServicesApiFp(this.configuration)
            .resetForgottenPassword(requestParameters.token, requestParameters.password, options)
            .then(request => request(this.axios, this.basePath));
    }
    public sendNotificationOnForgottenPassword(
        requestParameters: BusUserConnectionWebServicesApiSendNotificationOnForgottenPasswordRequest,
        options?: any,
    ) {
        return BusUserConnectionWebServicesApiFp(this.configuration)
            .sendNotificationOnForgottenPassword(requestParameters.userEmail, options)
            .then(request => request(this.axios, this.basePath));
    }
    public updateUserOnFirstConnection(
        requestParameters: BusUserConnectionWebServicesApiUpdateUserOnFirstConnectionRequest,
        options?: any,
    ) {
        return BusUserConnectionWebServicesApiFp(this.configuration)
            .updateUserOnFirstConnection(requestParameters.token, requestParameters.userSettingsUpdateDto, options)
            .then(request => request(this.axios, this.basePath));
    }
}

// custom hook generation here
export type PatchResetForgottenPasswordResponse = AxiosResponse<void>;
export const KeyPatchResetForgottenPassword = 'PatchResetForgottenPassword';
export const useMutationPatchResetForgottenPassword = (
    options?: UseMutationOptions<
        PatchResetForgottenPasswordResponse,
        AxiosError,
        BusUserConnectionWebServicesApiResetForgottenPasswordRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const res = useMutation<
        PatchResetForgottenPasswordResponse,
        AxiosError,
        BusUserConnectionWebServicesApiResetForgottenPasswordRequest
    >(
        (args: BusUserConnectionWebServicesApiResetForgottenPasswordRequest) =>
            new BusUserConnectionWebServicesApi(new Configuration()).resetForgottenPassword(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchResetForgottenPassword = (
    args: BusUserConnectionWebServicesApiResetForgottenPasswordRequest,
    options?: UseQueryOptions<PatchResetForgottenPasswordResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const res = useQuery<PatchResetForgottenPasswordResponse, AxiosError>(
        [KeyPatchResetForgottenPassword, args, options && options.queryKey],
        () =>
            new BusUserConnectionWebServicesApi(new Configuration({ ...conf })).resetForgottenPassword(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PostSendNotificationOnForgottenPasswordResponse = AxiosResponse<void>;
export const KeyPostSendNotificationOnForgottenPassword = 'PostSendNotificationOnForgottenPassword';
export const useMutationPostSendNotificationOnForgottenPassword = (
    options?: UseMutationOptions<
        PostSendNotificationOnForgottenPasswordResponse,
        AxiosError,
        BusUserConnectionWebServicesApiSendNotificationOnForgottenPasswordRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const res = useMutation<
        PostSendNotificationOnForgottenPasswordResponse,
        AxiosError,
        BusUserConnectionWebServicesApiSendNotificationOnForgottenPasswordRequest
    >(
        (args: BusUserConnectionWebServicesApiSendNotificationOnForgottenPasswordRequest) =>
            new BusUserConnectionWebServicesApi(new Configuration()).sendNotificationOnForgottenPassword(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPostSendNotificationOnForgottenPassword = (
    args: BusUserConnectionWebServicesApiSendNotificationOnForgottenPasswordRequest,
    options?: UseQueryOptions<PostSendNotificationOnForgottenPasswordResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const res = useQuery<PostSendNotificationOnForgottenPasswordResponse, AxiosError>(
        [KeyPostSendNotificationOnForgottenPassword, args, options && options.queryKey],
        () =>
            new BusUserConnectionWebServicesApi(new Configuration({ ...conf })).sendNotificationOnForgottenPassword(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
export type PatchUpdateUserOnFirstConnectionResponse = AxiosResponse<void>;
export const KeyPatchUpdateUserOnFirstConnection = 'PatchUpdateUserOnFirstConnection';
export const useMutationPatchUpdateUserOnFirstConnection = (
    options?: UseMutationOptions<
        PatchUpdateUserOnFirstConnectionResponse,
        AxiosError,
        BusUserConnectionWebServicesApiUpdateUserOnFirstConnectionRequest
    >,
    axiosConfig?: AxiosRequestConfig,
) => {
    const res = useMutation<
        PatchUpdateUserOnFirstConnectionResponse,
        AxiosError,
        BusUserConnectionWebServicesApiUpdateUserOnFirstConnectionRequest
    >(
        (args: BusUserConnectionWebServicesApiUpdateUserOnFirstConnectionRequest) =>
            new BusUserConnectionWebServicesApi(new Configuration()).updateUserOnFirstConnection(args, axiosConfig),
        options,
    );
    return { ...res, data: res.data?.data };
};
export const useQueryPatchUpdateUserOnFirstConnection = (
    args: BusUserConnectionWebServicesApiUpdateUserOnFirstConnectionRequest,
    options?: UseQueryOptions<PatchUpdateUserOnFirstConnectionResponse, AxiosError>,
    axiosConfig?: AxiosRequestConfig,
    conf?: ConfigurationParameters,
) => {
    const res = useQuery<PatchUpdateUserOnFirstConnectionResponse, AxiosError>(
        [KeyPatchUpdateUserOnFirstConnection, args, options && options.queryKey],
        () =>
            new BusUserConnectionWebServicesApi(new Configuration({ ...conf })).updateUserOnFirstConnection(
                args,
                axiosConfig,
            ),
        options,
    );
    return { ...res, data: res.data?.data };
};
