import { Box, styled } from '@mui/material';
import { ReactComponent as ArrStepCompleteIcon } from '../../../../../assets/icons/icon-step-complete-purple.svg';
import { ReactComponent as DepStepCompleteIcon } from '../../../../../assets/icons/icon-step-complete-green.svg';

export const StyledStepContainer = styled(Box)`
    display: flex;
    align-items: center;
    gap: 10px;
`;

export const StyledStepPendingCheckbox = styled(Box)`
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: #d5dde3;
`;

export const StyledArrStepCompleteIcon = styled(ArrStepCompleteIcon)`
    width: 12px;
    height: 12px;
`;

export const StyledDepStepCompleteIcon = styled(DepStepCompleteIcon)`
    width: 12px;
    height: 12px;
`;
