import { Box, Fab, styled } from '@mui/material';
import { headerHeightInPx } from '../../../utils/constants';

export const DispatcherPageContentContainer = styled(Box)`
    position: relative;
    height: calc(100% - ${headerHeightInPx}px);
    color: white;
    overflow: auto;
`;

export const StyledAutoScrollFab = styled(Fab)`
    position: absolute;
    right: 25px;
    top: 15px;
`;

export const StyledGanttContainer = styled(Box)`
    display: flex;
    width: fit-content;
    margin-bottom: 10px;
    padding-top: 10px;
    min-height: 100px;
`;

export const StyledCurrentTimeIndicator = styled(Box)`
    position: absolute;
    height: 100%;
    background-color: white;
    width: 1px;
`;
